<template>
  <a-layout style="padding: 0px; height: 100%; overflow: auto">
    <a-layout-content
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 60px;
      "
    >
      <div class="Enter-top">
        <div>
          <a-breadcrumb style="margin: 18px 0">
            <a-breadcrumb-item>巡检管理</a-breadcrumb-item>
            <a-breadcrumb-item>计划管理</a-breadcrumb-item>
          </a-breadcrumb>
        </div>
        <div
          v-if="!visible"
          class="flow-d-row"
          style="float: right; align-items: center"
        >
          <a-button
            style="margin-right: 15px"
            type="primary"
            @click="showDrawer"
          >
            新增计划
          </a-button>
          <a-button type="primary" @click="onschedule"> 排班信息 </a-button>
        </div>
        <div v-else>
          <a-icon
            @click="onClose"
            style="
              width: 30px;
              margin-right: 5px;
              cursor: pointer;
              font-size: 25px;
            "
            type="rollback"
          />
        </div>
      </div>
    </a-layout-content>
    <a-layout
      v-if="!visible"
      style="padding: 24px; height: 100%; overflow: auto"
    >
      <a-layout-content
        style="
          background-color: #fff;
          line-height: 60px;
          margin: 0;
          min-width: 1330px;
          width: 100%;
        "
      >
        <a-form
          style="padding: 0 24px"
          class="ant-advanced-search-form"
          :form="fom"
          @submit="handleSearch"
        >
          <a-row class="flex-d-row" style="flex-wrap: wrap" :gutter="24">
            <a-col v-for="(i, index) in textlist" :key="index">
              <a-form-item style="margin-bottom: 0" :label="i">
                <a-select
                  v-model="params['cpp.state'].value"
                  v-if="i == '状态'"
                  placeholder="请选择状态"
                  style="width: 173.5px"
                >
                  <a-select-option value=""> 全部 </a-select-option>
                  <a-select-option value="0"> 启用 </a-select-option>
                  <a-select-option value="1"> 禁用 </a-select-option>
                </a-select>
                <a-select
                  v-model="params['cpp.patrol_type'].value"
                  v-else-if="i == '计划类型'"
                  placeholder="请选择计划类型"
                  style="width: 173.5px"
                >
                  <a-select-option value="0"> 一次性 </a-select-option>
                  <a-select-option value="1"> 周期性 </a-select-option>
                </a-select>
                <a-select
                  v-model="params['cpp.cycle_type'].value"
                  v-else-if="i == '周期模式'"
                  placeholder="请选择周期模式"
                  style="width: 173.5px"
                >
                  <a-select-option value="0"> 日 </a-select-option>
                  <a-select-option value="1"> 周 </a-select-option>
                  <a-select-option value="2"> 月 </a-select-option>
                </a-select>
                <a-input
                  v-else
                  style="width: 173.5px"
                  v-decorator="[
                    `${i}`,
                    {
                      rules: [],
                    },
                  ]"
                  :placeholder="'请输入' + i"
                />
              </a-form-item>
            </a-col>
            <a-col class="a-col-top">
              <a-button type="primary" html-type="submit"> 搜索 </a-button>
              <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
                清空
              </a-button>
            </a-col>
          </a-row>
        </a-form>
      </a-layout-content>
      <a-layout-content
        style="background: #fff; padding: 24px; margin: 0; min-width: 1330px"
      >
        <!-- 表格 -->
        <div class="search-wrap">
          <a-table
            :row-selection="rowSelection"
            rowKey="id"
            :pagination="false"
            childrenColumnName="child"
            :columns="columns"
            :data-source="patrollist"
            :rowClassName="rowClassName"
          >
            <span style="font-family: '黑体'" slot="code" slot-scope="text"
              >{{ text }}
            </span>
            <span slot="startDate" slot-scope="text, record"
              >{{
                record.endDate == null
                  ? record.startDate + '-' + '无限期'
                  : record.startDate + '-' + record.endDate
              }}
            </span>
            <span slot="patrolType" slot-scope="text"
              >{{ text == '0' ? '一次性' : text == '1' ? '周期性' : '' }}
            </span>
            <span slot="cycleType" slot-scope="text"
              >{{
                text == 0 ? '日' : text == '1' ? '周' : text == '2' ? '月' : ''
              }}
            </span>
            <span slot="status" slot-scope="text, record">
              <a-switch
                :checked="text == 0 ? true : false"
                default-checked
                @change="onchangestate(record)"
              />
            </span>
            <template slot="action" slot-scope="text, record">
              <a @click="onEdit(record)">编辑</a>&nbsp;&nbsp;
              <a-popconfirm
                title="你确认要删除该计划么?"
                ok-text="是"
                cancel-text="否"
                @confirm="onDetele(record)"
                @cancel="cancel"
              >
                <a>删除</a>
              </a-popconfirm>
            </template>
          </a-table>
          <a-pagination
            v-model="params.current"
            :page-size="params.pageSize"
            show-quick-jumper
            :default-current="1"
            :total="total"
            @change="onpagesize"
          />
        </div>
      </a-layout-content>
    </a-layout>
    <a-layout v-else style="padding: 24px; height: 100%; overflow: auto">
      <a-layout-content
        v-if="form.id != 'pai'"
        style="background: #fff; padding: 24px; margin: 0; min-width: 1330px"
      >
        <div class="title-top">基本信息</div>
        <a-form-model
          ref="ruleForm"
          style="margin-bottom: 30px; padding-left: 150px; flex-wrap: wrap"
          class="flow-d-row"
          :rules="rules"
          :model="form"
        >
          <a-form-model-item class="input-top" label="计划编号">
            <a-input
              disabled="true"
              v-model="form.code"
              placeholder="系统自动生成"
            />
          </a-form-model-item>
          <a-form-model-item
            class="input-top"
            ref="name"
            prop="name"
            label="计划名称"
          >
            <a-input
              @blur="
                () => {
                  $refs.name.onFieldBlur()
                }
              "
              v-model="form.name"
            />
          </a-form-model-item>
          <a-form-model-item
            class="input-top"
            prop="patrolLineId"
            label="巡检路线"
          >
            <a-select
              v-model="form.patrolLineId"
              placeholder="请选择巡检路线"
              style="width: 100%"
            >
              <a-select-option v-for="item in linelist" :key="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
        <div class="title-top">计划信息</div>
        <a-form-model style="padding-left: 150px" :model="form">
          <a-form-model-item class="input-top1" label="计划类型">
            <a-radio-group @change="onchangezhou" v-model="form.patrolType">
              <a-radio :value="0"> 一次性 </a-radio>
              <a-radio :value="1"> 周期性 </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item
            class="input-top"
            prop="startDate"
            :label="'开始日期'"
          >
            <a-date-picker v-model="form.startDate" style="width: 100%" />
          </a-form-model-item>
          <a-form-model-item
            v-show="form.patrolType == 1"
            class="input-top"
            label="结束日期(不选为无限期)"
          >
            <a-date-picker v-model="form.endDate" style="width: 100%" />
          </a-form-model-item>
          <a-form-model-item
            v-show="form.patrolType == 1"
            class="input-top1"
            label="周期模式"
          >
            <a-radio-group @change="onChangemo" v-model="form.cycleType">
              <a-radio :value="0"> 日 </a-radio>
              <a-radio :value="1"> 周 </a-radio>
              <a-radio :value="2"> 月 </a-radio>
            </a-radio-group>
            <br />
            每&nbsp;&nbsp;<a-input
              style="width: 100px"
              v-model="form.cycleDuration"
            /><span v-if="form.cycleType == 0"> 日</span
            ><span v-if="form.cycleType == 1"> 周的</span
            ><span v-if="form.cycleType == 2"> 月的</span>
            <div v-show="form.cycleType != 0">
              <div class="flow-d-row" v-if="form.cycleType == 1">
                <div
                  v-for="(item, index) in weeklist"
                  :key="item"
                  class="rangeClass"
                  style="width: 66px; height: 34px; margin-right: 20px"
                >
                  <div
                    @click="onchec(index)"
                    class="active"
                    style="width: 64px; height: 32px; line-height: 32px"
                  >
                    {{ item }}
                  </div>
                </div>
              </div>
              <div
                style="flex-wrap: wrap"
                class="flow-d-row"
                v-if="form.cycleType == 2"
              >
                <div
                  v-for="(item, index) in mothlist"
                  :key="item"
                  class="rangeClass"
                  style="
                    width: 26px;
                    height: 26px;
                    margin-right: 10px;
                    margin-top: 10px;
                  "
                >
                  <div
                    @click="onchec(index)"
                    class="active"
                    style="width: 24px; height: 24px; line-height: 24px"
                  >
                    {{ item }}
                  </div>
                </div>
              </div>
            </div>
          </a-form-model-item>
          <a-form-model-item class="input-top1" label="巡检时间">
            <div>
              <div
                v-for="(item, index) in time"
                :key="item"
                class="flow-d-row"
                style="position: relative; margin-bottom: 15px"
              >
                <a-time-picker
                  class="input-top"
                  style="margin-left: 0px"
                  @change="ontime(index)"
                  v-model="time[index]"
                  format="HH:mm"
                />
                <a-icon v-if="time.length == 1" />
                <a-icon
                  @click="ondeletepic(index)"
                  v-else
                  class="delete-icon"
                  type="delete"
                />
              </div>
            </div>
            <div style="margin-right: -10px; width: 260px">
              <button type="button" @click="onpicker" class="adddata">
                <span class="fontClass"
                  ><i
                    data-v-6ae31b3c=""
                    class="rs-icon-circle-plus-outline"
                  ></i>
                  添加时间段
                </span>
              </button>
            </div>
          </a-form-model-item>
        </a-form-model>
        <div class="title-top">执行人信息</div>
        <a-form-model
          ref="ruleForm"
          style="margin-bottom: 30px; padding-left: 150px; flex-wrap: wrap"
          class="flow-d-row"
          :rules="rules"
          :model="form"
        >
          <a-form-model-item class="input-top" label="执行模式">
            <a-select
              @change="onclin"
              placeholder="请选择处理人"
              v-model="form.executorType"
              style="width: 100%"
            >
              <a-select-option key="0"> 指定执行人 </a-select-option>
              <a-select-option key="1"> 指定执行角色 </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            v-if="form.executorType == 0"
            class="input-top"
            label="执行人"
          >
            <a-select
              @change="onchange1"
              v-model="executorId"
              placeholder="请选择执行人"
              style="width: 100%"
            >
              <a-select-option
                v-for="item in userlist"
                :key="item.id + 'T' + item.name"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item v-else class="input-top" label="执行人">
            <a-select
              v-model="executorRoleId"
              @change="onchange2"
              placeholder="请选择执行人"
              style="width: 100%"
            >
              <a-select-option
                v-for="item in positionslist"
                :key="item.id + 'T' + item.name"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </a-layout-content>
      <a-layout-content
        v-else
        style="background: #fff; padding: 24px; margin: 0; min-width: 1330px"
      >
        <a-calendar
          :header-render="headerRender"
          :validRange="aa"
          @panelChange="onPanelChange"
        >
          <ul slot="dateCellRender" slot-scope="value" class="events">
            <li v-for="item in getListData(value)" :key="item.content">
              <a-tooltip>
                <template slot="title">
                  <span>{{ item.name }} &nbsp;&nbsp;</span>
                  <span>{{ item.executorName }}&nbsp;&nbsp;</span>
                  <span v-for="it in JSON.parse(item.patrolTime)" :key="it"
                    >{{ it }}&nbsp;</span
                  >
                </template>
                {{ item.name }}&nbsp;&nbsp;&nbsp;&nbsp;{{ item.executorName }}
                <hr style="margin-right: 35px; border: 1px solid #d9d9d9" />
              </a-tooltip>
            </li>
          </ul>
          <template slot="monthCellRender" slot-scope="value">
            <div v-if="getMonthData(value)" class="notes-month">
              <section>{{ getMonthData(value) }}</section>
              <span>Backlog number</span>
            </div>
          </template>
        </a-calendar>
      </a-layout-content>
    </a-layout>
    <a-layout-content
      v-if="visible == true"
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 70px;
        text-align: center;
      "
    >
      <div v-if="form.id == 'pai'" save-draw>
        <a-button @click="onClose" style="margin-left: 40px"> 取消 </a-button>
      </div>
      <div v-else save-draw>
        <a-button html-type="submit" type="primary" @click="onSubmit">
          提交
        </a-button>
        <a-button @click="onClose" style="margin-left: 40px"> 取消 </a-button>
      </div>
    </a-layout-content>
  </a-layout>
</template>
<script>
const columns = [
  {
    title: '计划编号',
    dataIndex: 'code',
    scopedSlots: { customRender: 'code' },
  },
  {
    title: '计划名称',
    dataIndex: 'name',
  },
  {
    title: '路线名称',
    dataIndex: 'patrolLineName',
  },
  {
    title: '计划类型',
    dataIndex: 'patrolType',
    scopedSlots: { customRender: 'patrolType' },
  },
  {
    title: '计划时间',
    dataIndex: 'startDate',
    scopedSlots: { customRender: 'startDate' },
  },
  {
    title: '周期模式',
    dataIndex: 'cycleType',
    scopedSlots: { customRender: 'cycleType' },
  },
  {
    title: '状态',
    dataIndex: 'state',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '操作',
    dataIndex: 'id',
    scopedSlots: { customRender: 'action' },
  },
]
import http from '../../../http'
const api = process.env.VUE_APP_API_BASE_URL
import moment from 'moment'
export default {
  data() {
    return {
      columns,
      visible: false,
      selectedRowKey: '',
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      apiwei: api + 'patrol/site/qrExport',
      title: '',
      textlist: ['计划编号', '计划名称', '计划类型', '周期模式', '状态'],
      expand: false,
      fom: this.$form.createForm(this, { name: 'advanced_search' }),
      value: '',
      res: {
        credit: {
          securityToken: '',
          accessKeySecret: '',
          accessKeyId: '',
        },
        region: '',
      },
      params: {
        current: 1,
        pageSize: 10,
        'cpp.code': {
          value: '',
          op: '%like%',
        },
        'cpp.name': {
          value: '',
          op: '%like%',
        },
        'cpp.patrol_type': {
          value: undefined,
          op: '=',
        },
        'cpp.cycle_type': {
          value: undefined,
          op: '=',
        },
        'cpp.state': {
          value: undefined,
          op: '=',
        },
      },
      weeklist: ['一', '二', '三', '四', '五', '六', '日'],
      mothlist: [
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        '23',
        '24',
        '25',
        '26',
        '27',
        '28',
        '29',
        '30',
        '31',
      ],
      total: 1,
      positionslist: [],
      userlist: [],
      linelist: [],
      Id: '',
      patrollist: [],
      time: [''],
      executorId: '',
      executorRoleId: '',
      schedulelist: [],
      aa: '',
      moth: '',
      form: {
        id: '',
        code: '',
        cycleDate: [],
        name: '',
        patrolLineId: '',
        patrolType: 0,
        executorType: '0',
        startDate: '',
        patrolTime: [''],
        cycleType: 0,
        endDate: '',
        cycleDuration: '',
        state: '',
        executorId: '',
        executorName: '',
        executorRoleId: '',
        executorRoleName: '',
      },
      e: '',
      rules: {
        name: [
          {
            required: true,
            message: '巡检点位名称不能为空',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  filters: {
    time(time) {
      if (time == null) {
        return ' '
      } else {
        var times = time.split('T')
        return times[1]
      }
    },
  },
  methods: {
    getListData(value) {
      let tim = moment(value).format('YYYY-MM').split('-')[1]
      //   console.log(value.date());
      if (tim != this.moth) {
        return
      }
      let listData
      switch (value.date()) {
        case 1:
          listData = this.schedulelist[1]
          break
        case 2:
          listData = this.schedulelist[2]
          break
        case 3:
          listData = this.schedulelist[3]
          break
        case 4:
          listData = this.schedulelist[4]
          break
        case 5:
          listData = this.schedulelist[5]
          break
        case 6:
          listData = this.schedulelist[6]
          break
        case 7:
          listData = this.schedulelist[7]
          break
        case 8:
          listData = this.schedulelist[8]
          break
        case 9:
          listData = this.schedulelist[9]
          break
        case 10:
          listData = this.schedulelist[10]
          break
        case 11:
          listData = this.schedulelist[11]
          break
        case 12:
          listData = this.schedulelist[12]
          break
        case 13:
          listData = this.schedulelist[13]
          break
        case 14:
          listData = this.schedulelist[14]
          break
        case 15:
          listData = this.schedulelist[15]
          break
        case 16:
          listData = this.schedulelist[16]
          break
        case 17:
          listData = this.schedulelist[17]
          break
        case 18:
          listData = this.schedulelist[18]
          break
        case 19:
          listData = this.schedulelist[19]
          break
        case 20:
          listData = this.schedulelist[20]
          break
        case 21:
          listData = this.schedulelist[21]
          break
        case 22:
          listData = this.schedulelist[22]
          break
        case 23:
          listData = this.schedulelist[23]
          break
        case 24:
          listData = this.schedulelist[24]
          break
        case 25:
          listData = this.schedulelist[25]
          break
        case 26:
          listData = this.schedulelist[26]
          break
        case 27:
          listData = this.schedulelist[27]
          break
        case 28:
          listData = this.schedulelist[28]
          break
        case 29:
          listData = this.schedulelist[29]
          break
        case 30:
          listData = this.schedulelist[30]
          break
        case 31:
          listData = this.schedulelist[31]
          break
        default:
      }
      //   console.log(listData);
      return listData || []
    },

    getMonthData(value) {
      if (value.month() === 8) {
        return 1394
      }
    },
    onPanelChange(e) {
      let Dat = moment(e).format('YYYY-MM').split('-')
      this.moth = Dat[1]
      this.getschedule(Dat[0], Dat[1])
      this.aa = [moment(Dat + '-01'), moment(Dat + '-31')]
    },
    onschedule() {
      this.form.id = 'pai'
      this.visible = true
      localStorage.setItem('selectedKeys', ['/patrol/plan?p'])
      this.$store.commit('selectKeys')
      let D = moment().format('YYYY-MM').split('-')
      this.moth = D[1]
      this.getschedule(D[0], D[1])
      this.aa = [moment(D + '-01'), moment(D + '-31')]
    },
    handleSearch(e) {
      e.preventDefault()
      this.params.current = 1
      this.fom.validateFields((error, values) => {
        // console.log(values);
        this.params['cpp.name'].value = values.计划名称
        this.params['cpp.code'].value = values.计划编号
      })
      this.getpatrolplan()
    },
    handleReset() {
      this.fom.resetFields()
      this.params['cpp.patrol_type'].value = undefined
      this.params['cpp.cycle_type'].value = undefined
      this.params['cpp.state'].value = undefined
    },
    toggle() {
      this.expand = !this.expand
    },
    onchec(e) {
      //   console.log(this.form.cycleDate);
      let sy = document.getElementsByClassName('active')
      //   console.log(this.form.cycleDate.indexOf(e + 1));
      //   console.log(thi/s.form.cycleDate);
      if (this.form.cycleDate.indexOf(e + 1) == -1) {
        sy[e].style.backgroundColor = '#1890FF'
        sy[e].style.color = '#fff'
        this.form.cycleDate.push(e + 1)
      } else {
        this.form.cycleDate.splice(e, 1)
        sy[e].style.backgroundColor = '#fff'
        sy[e].style.color = '#666'
      }
      //   console.log(this.form.cycleDate);
    },
    ontime(e) {
      let a = moment(this.time[e]).format('YYYY-MM-DD HH:mm')
      this.form.patrolTime[e] = a.split(' ')[1]
      //   console.log(a.split(" ")[1]);
    },
    onpicker() {
      this.time.push('')
      this.form.patrolTime.push('')
      //   console.log(this.form.patrolTime);
    },
    ondeletepic(e) {
      this.time.splice(e, 1)
      this.form.patrolTime.splice(e, 1)
    },
    onClose() {
      this.visible = false
      this.time = []
      this.executorId = ''
      this.schedulelist = []
      this.executorRoleId = ''
      window.location = window.location.href.split('?')[0]
      this.form = {
        id: '',
        code: '',
        cycleDate: [],
        name: '',
        patrolLineId: '',
        patrolType: 0,
        executorType: '0',
        startDate: '',
        patrolTime: [''],
        cycleType: 0,
        endDate: '',
        cycleDuration: '',
        state: '',
        executorId: '',
        executorName: '',
        executorRoleId: null,
        executorRoleName: '',
      }
    },
    headerRender({ value, onChange }) {
      const start = 0
      const end = 12
      const monthOptions = []

      const current = value.clone()
      const localeData = value.localeData()
      const months = []
      for (let i = 0; i < 12; i++) {
        current.month(i)
        months.push(localeData.monthsShort(current))
      }

      for (let index = start; index < end; index++) {
        monthOptions.push(
          <a-select-option class="month-item" key={`${index}`}>
            {months[index]}
          </a-select-option>
        )
      }
      const month = value.month()

      const year = value.year()
      const options = []
      for (let i = year - 10; i < year + 10; i += 1) {
        options.push(
          <a-select-option key={i} value={i} class="year-item">
            {i}
          </a-select-option>
        )
      }
      return (
        <div style={{ padding: '10px' }}>
          <a-row type="flex" justify="end">
            <a-col>
              <a-select
                show-search
                style="width:90px;height:32px"
                dropdownMatchSelectWidth={false}
                class="my-year-select"
                onChange={newYear => {
                  const now = value.clone().year(newYear)
                  onChange(now)
                }}
                value={String(year)}
              >
                {options}
              </a-select>
            </a-col>
            <a-col>
              <a-select
                style="width:90px;height:32px;margin-left:10px"
                dropdownMatchSelectWidth={false}
                value={String(month)}
                onChange={selectedMonth => {
                  const newValue = value.clone()
                  newValue.month(parseInt(selectedMonth, 10))
                  onChange(newValue)
                }}
              >
                {monthOptions}
              </a-select>
            </a-col>
          </a-row>
        </div>
      )
    },
    onSubmit() {
      //   console.log("submit!", this.form);
      this.form.patrolTime.forEach((item, index) => {
        if (item == '') {
          this.form.patrolTime.splice(index, 1)
        }
      })
      if (this.form.executorId == '' && this.form.executorRoleId == '') {
        this.$message.error('执行人不能为空')
        return
      }
      if (this.form.startDate == '') {
        this.$message.error('开始时间不能为空')
        return
      }
      if (this.form.patrolTime.length > 0) {
        this.form.patrolTime = JSON.stringify(this.form.patrolTime)
        this.form.cycleDate = JSON.stringify(this.form.cycleDate)
        if (this.form.id == '') {
          this.Addpatrolplan()
        } else {
          this.putpatrolplan()
        }
      } else {
        this.$message.error('巡检时间不能为空')
      }
    },
    onchangezhou() {
      if (this.form.patrolType == 1) {
        this.form.cycleDuration = 1
      } else {
        this.form.cycleDuration = ''
      }
    },
    onchangestate(e) {
      //   console.log(e);
      if (e.state == 1) {
        this.form.state = 0
      } else if (e.state == 0) {
        this.form.state = 1
      }
      this.getpatrolplanstate(e.id, this.form.state)
    },
    // 删除
    onDetele(e) {
      this.deletepatrolplan([e.id])
    },
    async deletepatrolplan(id) {
      try {
        const res = await http.deletepatrolplan(id)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('提交成功')
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        this.$message.error(ex)
        console.log('error:' + ex)
      }
    },
    // 编辑
    onEdit(item) {
      this.form.id = item.id
      this.title = '编辑'
      this.visible = true
      this.getpatrolplanId(item.id)
    },
    onpagesize(e) {
      this.params.current = e
      this.getpatrolplan()
    },
    showDrawer() {
      this.visible = true
      this.title = '新增'
      this.$store.commit('selectKeys')
    },
    onchange1(e) {
      let use = e.split('T')
      this.form.executorId = use[0]
      this.form.executorName = use[1]
    },
    onchange2(e) {
      let use = e.split('T')
      this.form.executorRoleId = use[0]
      this.form.executorRoleName = use[1]
    },
    async getschedule(year, month) {
      try {
        const res = await http.getschedule(year, month)
        const { success, data } = res.data
        if (success) {
          this.schedulelist = data.schedule
          //   console.log(data.schedule);
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    onclin() {
      this.executorId = ''
      this.executorRoleId = ''
      this.form.executorId = ''
      this.form.executorRoleId = ''
    },
    async getpatrolplan() {
      try {
        const res = await http.getpatrolplan(this.params)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.patrollist = data.plans.records
          this.total = data.plans.total
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    //新增
    async Addpatrolplan() {
      try {
        const res = await http.Addpatrolplan(this.form)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('提交成功')
          this.onClose()
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    //修改
    async putpatrolplan() {
      try {
        const res = await http.putpatrolplan(this.form)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('提交成功')
          this.onClose()
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async getpatrolplanId(id) {
      try {
        const res = await http.getpatrolplanId(id)
        const { success, data } = res.data
        if (success) {
          this.time = []
          this.form.code = data.plan.code
          this.form.cycleDate = JSON.parse(data.plan.cycleDate)
          this.form.name = data.plan.name
          this.form.patrolLineId = data.plan.patrolLineId + ''
          this.form.patrolType = data.plan.patrolType
          this.form.executorType = data.plan.executorType + ''
          this.form.startDate = data.plan.startDate
          this.form.patrolTime = JSON.parse(data.plan.patrolTime)
          JSON.parse(data.plan.patrolTime).forEach(item => {
            this.time.push(this.form.startDate + ' ' + item)
          })
          this.form.cycleType = data.plan.cycleType
          this.form.endDate = data.plan.endDate
          this.form.cycleDuration = data.plan.cycleDuration
          this.form.executorId = data.plan.executorId
          this.form.executorName = data.plan.executorName
          this.form.executorRoleId = data.plan.executorRoleId
          this.form.executorRoleName = data.plan.executorRoleName
          this.executorId = data.plan.executorId + 'T' + data.plan.executorName
          this.executorRoleId =
            data.plan.executorRoleId + 'T' + data.plan.executorRoleName
          //   console.log(data);
          setTimeout(() => {
            let sy = document.getElementsByClassName('active')
            this.form.cycleDate.forEach(item => {
              sy[item - 1].style.backgroundColor = '#1890FF'
              sy[item - 1].style.color = '#fff'
            })
          }, 100)
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    onChangemo() {
      this.form.cycleDate = []
    },
    //状态
    async getpatrolplanstate(id, state) {
      try {
        const res = await http.getpatrolplanstate(id, state)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('提交成功')
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        this.$message.error(ex)
        console.log('error:' + ex)
      }
    },
    async getpatrollinelist() {
      try {
        const res = await http.getpatrollinelist()
        const { success, data } = res.data
        if (success) {
          this.linelist = data.lines
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getuserlist() {
      try {
        const res = await http.getuserlist()
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.userlist = data.users
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getconfigpositionlist() {
      try {
        const res = await http.getconfigpositionlist()
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.positionslist = data.positions
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    timer() {
      return setTimeout(() => {
        this.getpatrolplan()
      }, 100)
    },
    rowClassName(record, index) {
      let className = 'light'
      if (index % 2 === 1) className = 'dark'
      return className
    },
  },
  mounted() {
    this.getpatrolplan()
    this.getpatrollinelist()
    this.getuserlist()
    this.getconfigpositionlist()
    let users = JSON.parse(localStorage.getItem('store-userlist'))
    this.form.projectId = users.projectInfo.projectId
    if (this.$route.query.visible == 'true') {
      this.showDrawer()
    }
    if (this.$route.query.index == 'p') {
      this.onschedule()
    }
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKey, selectedRows) => {
          this.selectedRowKey = selectedRowKey
          //   console.log(selectedRowKey);
          console.log(selectedRows)
        },
      }
    },
  },
}
</script>
<style scoped>
.search-wrap {
  width: 100%;
  border-radius: 3px;
  position: relative;
  min-height: 600px;
  height: 100%;
  padding: 10px 0 6px;
}
.ant-upload-text {
  font-size: 13px;
}
.ant-pagination {
  float: right;
  margin-top: 10px;
}
.flex-d-row {
  display: flex;
  flex-direction: row;
}
.ant-layout {
  height: 100%;
}
.flow-d-row {
  display: flex;
  flex-direction: row;
}
.icon-url {
  position: absolute;
  right: -25px;
  font-size: 18px;
  top: 0px;
  cursor: pointer;
}
.ant-advanced-search-form .ant-form-item {
  height: 80px;
  margin-bottom: 15px;
}
.ant-form-item {
  /* height: 50px; */
  margin-bottom: 5px;
}
.a-col-top {
  position: absolute;
  right: 0;
  top: 43px;
}
.Enter-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
}
.a-col-span {
  display: inline-block;
  width: 24px;
  text-align: center;
}
::v-deep .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
  padding-left: 10px;
}
.ant-input {
  border-radius: 0;
  padding-left: 10px;
  color: #000;
}
.events {
  list-style: none;
  margin: 0;
  padding: 0;
}
.events .ant-badge-status {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px;
}
.notes-month {
  text-align: center;
  font-size: 28px;
}
.notes-month section {
  font-size: 28px;
}
.input-top {
  width: 260px;
  margin-left: 15px;
}
.input-top1 {
  width: 70%;
  margin-left: 15px;
}
::v-deep .ant-popover-inner-content {
  padding: 5;
}
::v-deep .ant-form-item-label {
  text-align: right;
}
::v-deep .ant-table .light {
  background-color: white;
}
::v-deep .ant-table .dark {
  background-color: #fafafa;
}
::v-deep .ant-upload-list-picture-card .ant-upload-list-item,
::v-deep .ant-upload-select-picture-card,
::v-deep .ant-upload-list-picture-card-container {
  width: 70px;
  height: 70px;
}
.ant-calendar-picker-input.ant-input {
  border-radius: 0;
}
.adddata {
  border: 1px dashed gray;
  color: #1890ff;
  width: 100%;
  background-color: #fff;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
}
.delete-icon {
  position: absolute;
  font-size: 20px;
  left: 270px;
  top: 5px;
  color: #999;
}
.rangeClass .active {
  border-radius: 4px;
  font-size: 14px;
  color: #666;
  text-align: center;
}
.rangeClass {
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.25);
  font-size: 14px;
  color: rgba(0, 0, 0, 0.25);
  text-align: center;
  line-height: 20px;
  border-radius: 4px;
}
.title-top {
  font-size: 18px;
  font-weight: bold;
  margin: 15px 0;
  border-left: 3px solid #1890ff;
  color: #000;
  padding-left: 10px;
}
</style>
